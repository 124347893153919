export const configureIubendaGTMCallbacks = () => {
  if (global._iub && global._iub.csConfiguration) {
    global._iub.csConfiguration.callback = global._iub.csConfiguration.callback || {}
    global._iub.csConfiguration.callback.onPreferenceExpressedOrNotNeeded = function (preference) {
      global.dataLayer.push({
        iubenda_ccpa_opted_out: global._iub.cs.api.isCcpaOptedOut()
      });
      if (!preference) {
        global.dataLayer.push({
          event: "iubenda_preference_not_needed"
        });
      } else {
        if (preference.consent === true) {
          global.dataLayer.push({
            event: "iubenda_consent_given"
          });
        } else if (preference.consent === false) {
          global.dataLayer.push({
            event: "iubenda_consent_rejected"
          });
        } else if (preference.purposes) {
          for (var purposeId in preference.purposes) {
            if (preference.purposes[purposeId]) {
              global.dataLayer.push({
                event: "iubenda_consent_given_purpose_" + purposeId
              });
            }
          }
        }
      }
    }
  }
}