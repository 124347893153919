import {alias} from 'src/utilities/locale'

export const linkResolver = (doc) => {
  const lang = alias(doc.lang)

  if (doc.url) {
    return doc.url
  }
  // URL for a page type
  if (doc.type === 'homepage') {
    return `/${lang}/`
  }

  if (doc.type === 'homepage_15') {
    return `/${lang}/`
  }

  if (doc.type === 'how_it_works') {
    return `/${lang}/how-it-works`
  }

  if (doc.type === 'how_it_works_15') {
    return `/${lang}/how-it-works`
  }

  if (doc.type === 'partners') {
    return `/${lang}/partners`
  }

  if (doc.type === 'for_merchants_15') {
    return `/${lang}/for-merchants`
  }

  if (doc.type === 'our_merchants') {
    return `/${lang}/for-merchants`
  }

  if (doc.type === 'info_page') {
    return `/${lang}/${doc.uid}`
  }

  if (doc.type === 'landing_page') {
    return `/${lang}/${doc.uid}`
  }

  if (doc.type === 'shop_director') {
    return `/${lang}/shop-here`
  }

  if (doc.type === 'know_your_worth') {
    return `/${lang}/master-your-money`
  }

  // Backup for all other types
  return `/${lang}/${(doc.type || '').replace(/_/g, '-')}`
}
