module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"omitPrismicScript":false,"repositoryName":"laybuy","accessToken":"MC5YT0kwRlJBQUFOQmtFbHJu.MWrvv71077-977-977-9Pe-_ve-_ve-_ve-_ve-_vVoJ77-977-9XWVU77-9IXTvv71r77-977-977-9NO-_ve-_ve-_vQ","path":"/preview","previews":true,"langs":["en-nz","en-gb","en-au","en-us"],"pages":[{"type":"Homepage_15","match":"/:lang/","path":"/home","component":"/opt/build/repo/src/templates/home.js"},{"type":"Info_page","match":"/:lang/:uid","path":"/info_page","component":"/opt/build/repo/src/templates/info_page.js"},{"type":"Landing_page","match":"/:lang/:uid","path":"/landing_page","component":"/opt/build/repo/src/templates/landing_page.js"},{"type":"How_it_works_15","match":"/:lang/how-it-works","path":"/how-it-works","component":"/opt/build/repo/src/templates/how_it_works.js"},{"type":"Shop_director","match":"/:lang/shop-here","path":"/shop-here","component":"/opt/build/repo/src/templates/shop_directory.js"},{"type":"For_merchants_15","match":"/:lang/for-merchants","path":"/for-merchants","component":"/opt/build/repo/src/templates/our_merchants.js"},{"type":"Partners","match":"/:lang/partners","path":"/partners","component":"/opt/build/repo/src/templates/partners.js"},{"type":"Know_your_worth","match":"/:lang/master-your-money","path":"/master-your-money","component":"/opt/build/repo/src/templates/know_your_worth.js"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"cookiePolicyInOtherWindow":true,"perPurposeConsent":true,"purposes":"1, 5","enableCMP":true,"lang":"en","siteId":1676874,"consentOnContinuedBrowsing":false,"cookiePolicyId":11468932,"cookiePolicyUrl":"https://www.laybuy.com/uk/privacy","gdprAppliesGlobally":true,"countryDetection":true,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#8378ff","acceptButtonCaptionColor":"white","customizeButtonColor":"#212121","customizeButtonCaptionColor":"white","rejectButtonDisplay":true,"rejectButtonColor":"#787878","rejectButtonCaptionColor":"white","position":"bottom","textColor":"#5f5f5f","backgroundColor":"#e3eaf1"}},"googleTagManagerOptions":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WP75JNS","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
