import React, { useState } from 'react';

export const GlobalStateContext = React.createContext();

const GlobalStateProvider = props => {
  const [globalState, setState] = useState({ siteBannerVisible: true });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState: (newState) => setState({ ...globalState, ...newState }) }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
};

export default ({ element }) => (
  <GlobalStateProvider>
    {element}
  </GlobalStateProvider>
);